import * as React from 'react';
import HeroCarouselWide from '@components/Sliders/HeroCarouselWide';
import LayoutPrivate from '@components/layouts/LayoutPrivate';
import SpinnerDotPage from '@components/Spinners/SpinnerDotPage';
import { useGetDasboardInformation } from '../helper/services';

const Dashboard = () => {
  const { books, isLoading, isError } = useGetDasboardInformation();

  if (!isLoading) console.log('books:', books);

  return (
    <LayoutPrivate pageTitle="Dashboard">
      <div className="bg-center bg-black z-0 relative">
        {/* Hero carousel */}
        {isLoading ? <SpinnerDotPage /> : isError ? <div>Error</div> : <HeroCarouselWide books={books} />}
        {/* End of carousel */}
      </div>
    </LayoutPrivate>
  );
};
export default Dashboard;
