import React, { useState, useEffect } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import { URL_ASSETS } from 'gatsby-env-variables';
import HeroSliderSlide from './HeroSliderSlide.js';

const HeroCarouselWide = ({ books }) => {
  const data = useStaticQuery(
    graphql`
      query {
        hero_slider_bg1: file(relativePath: { eq: "kiran-volcano-background.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        hero_slider_bg2: file(relativePath: { eq: "slide2.jpg" }) {
          childImageSharp {
            gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `
  );

  const hero_slider_bg1 = getImage(data.hero_slider_bg1);
  const hero_slider_bg2 = getImage(data.hero_slider_bg2);

  const sliderWrapperStyle = {
    position: 'relative',
    width: '100%',
    height: '100vh', // ajusta la altura según tu necesidad
    overflow: 'hidden',
    background: 'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0))',
    boxShadow: 'inset 0 -10px 10px -10px rgba(0, 0, 0, 0.5)',
  };

  const slideStyle = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    transition: 'opacity 1s ease-in-out',
  };

  const HeroSlider = ({ children }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentSlide(currentSlide === children.length - 1 ? 0 : currentSlide + 1);
      }, 6000);

      return () => clearInterval(intervalId);
    }, [currentSlide, children.length]);

    return (
      <div style={sliderWrapperStyle}>
        {React.Children.map(children, (child, index) =>
          index === currentSlide ? React.cloneElement(child, { style: { ...slideStyle, opacity: index === currentSlide ? 1 : 0 } }) : null
        )}
      </div>
    );
  };

  return (
    <>
      <div className="hero-slider block relative">
        <HeroSlider>
          <HeroSliderSlide
            backgroundImage={hero_slider_bg1}
            author="Kiran Bhat"
            title="BOOK ONE: AS WE ARE"
            description="It is the early days of 2021. COVID has ravaged the world, resulting in a son previously estranged from his parents returning home. "
            link="/chapter/?id=1&book=1"></HeroSliderSlide>

          <HeroSliderSlide
            backgroundImage={hero_slider_bg2}
            author="Kiran Bhat"
            title="BOOK TWO: FROM MY PERSPECTIVE"
            description="How do Mother and Father respond to having a son that they can barely relate to after a decade of separation?"
            link="/chapter/?id=36&book=2"></HeroSliderSlide>
        </HeroSlider>

        <div className="col-span-12 xl:block mt-auto image-strap z-10">
          <div className="flex justify-center gap-2 hero-content-books">
            {books &&
              books.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="flex flex-row text-white bg-gradient-strap"
                    style={{
                      backgroundImage: `url(${URL_ASSETS}${item.main_image_url})`,
                    }}>
                    <a
                      className=" bg-strap-image w-[260px] pb-3 px-3"
                      href={item.chapters[0] ? `/chapter/?id=${item.chapters[0]?.id}&book=${item.id}` : '#'}>
                      <div className="strap-image-copy uppercase font-bold font-rokkit text-xs text-left ">{item.name}</div>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>

        {/* <div className="gradienthero"></div> */}
      </div>
    </>
  );
};

export default HeroCarouselWide;
