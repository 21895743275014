import zIndex from '@mui/material/styles/zIndex';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

const HeroSliderSlide = ({ backgroundImage, author, title, description, link }) => {
  return (
    <div className="z-0">
      <div className="relative">
        <div className="gradienthero z-10"></div>
        <GatsbyImage image={backgroundImage} alt="" className="w-full image-hero z-0" />
        <div className="xl:col-span-12 col-span-12 description-slider  z-20">
          <div className="lg:col-span-5 col-span-12 lg:text-left text-center lg:pt-10 pt-2 font-rokkit lg:pr-5">
            <div className="slide-description max-w-[600px] mx-auto">
              {author ? <p className="text-white xl:text-base text-[14px] font-opensans font-light mb-1">Author: {author}</p> : null}
              <h1 className="font-bold tracking-widest text-white md:text-5xl text-3xl font-rokkit mb-3">{title}</h1>
              <p className="text-white first-paragraphs-1 md:text-base text-[14px] font-opensans mb-6">{description}</p>
            </div>

            {/* button read free */}
            <Link to={link}>
              <button className="btn-slide btn-xs xl:px-5 xl:mt-4 py-4 px-4 font-opensans rounded-md bottom-10 button-white transition-all duration-500">
                Read now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSliderSlide;
